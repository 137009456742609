<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="单据日期：">
          <el-date-picker v-model="time" :default-time="['00:00:00','23:59:59']" @change="changeTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="销售人员">
          <el-select v-model="searchForm.driverId" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in driverList" :key="index" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退款类型">
          <el-select v-model="searchForm.refundType" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">销售退货单列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <!-- <el-button size="mini" type="primary" @click="updateOrderStatus" :disabled="ids.length == 0">批量修改订单状态</el-button> -->
          <el-button size="mini" type="primary" @click="exportEvent" :disabled="ids.length == 0">导出</el-button>
          <el-button size="mini" type="primary" @click="printEvent" :disabled="ids.length == 0">打印</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" height="600" :data="tableData" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
            <!-- :selectable="selectStatus" -->
            <el-table-column type="selection" width="100">
            </el-table-column>
            <el-table-column prop="" label="序号" width="100">
              <template slot-scope="scope">
                {{scope.$index+1}}
              </template>
            </el-table-column>
            <el-table-column prop="refundType " label="退款类型">
              <template slot-scope="scope">
                <p v-if="scope.row.refundType == 0" style="color: #409eff">
                  退货退款
                </p>
                <p v-else-if="scope.row.refundType == 1" style="color: #67c23a">
                  退货无需退款
                </p>
                <p v-else style="color: #f56c6c">仅退款</p>
              </template>
            </el-table-column>
            <el-table-column prop="created" label="单据日期" width="200">
            </el-table-column>
            <el-table-column prop="id" label="单据编号">
              <template slot-scope="scope">
                <span class="orderId" >{{scope.row.orderId}}</span>
                <!-- @click="goSalesDelivery(scope.row)" -->
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="客户">

            </el-table-column>
            <el-table-column prop="driverName" label="销售人员">
              <template slot-scope="scope">
                {{scope.row.driverName?scope.row.driverName:'自提'}}
              </template>
            </el-table-column>
            <el-table-column prop="title" label="商品名称">
            </el-table-column>
            <el-table-column prop="standard" label="规格" width="200">
            </el-table-column>
            <el-table-column prop="num" label="数量">
            </el-table-column>
            <el-table-column prop="price" label="单价">
              <template slot-scope="scope">
                {{ scope.row.price }} 元
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="总金额">
              <template slot-scope="scope">
                {{ scope.row.totalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="warehouseName" label="仓库">
            </el-table-column>
            <el-table-column prop="createByName" label="制单人">
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>

    </div>

    <!-- 打印表格 -->
    <el-dialog title="打印信息" :visible.sync="printTableShow" width="44%">
      <template>
        <div id="print">
          <p style="text-align:center; margin-top:20px">销售退货单列表</p>
          <el-table :data="printData.list" show-summary :summary-method="getSummaries" style="width: 100%;">
            <el-table-column prop="created" label="单据日期" min-width="95">
            </el-table-column>
            <!-- <el-table-column
            prop="id"
            label="单据编号"
            min-width="150">
          </el-table-column> -->
            <el-table-column prop="driverName" label="销售人员">
            </el-table-column>
            <el-table-column prop="userName" label="客户">
            </el-table-column>
            <el-table-column prop="title" label="商品名称">
            </el-table-column>
            <el-table-column prop="standard" label="规格">
            </el-table-column>
            <el-table-column prop="num" label="数量" width="60">
            </el-table-column>
            <el-table-column prop="totalPrice" label="销售金额">
            </el-table-column>
            <el-table-column prop="refundType2" label="退款类型">
            </el-table-column>
            <el-table-column prop="warehouseName" label="仓库名称">
            </el-table-column>
            <el-table-column prop="createByName" label="制单人">
            </el-table-column>
          </el-table>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="printTableShow = false">取 消</el-button>
          <el-button ref="printBtn" type="primary" v-print="'#print'">确 定</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { resolve } from 'mathjs';

export default {
  data() {
    return {
      statusList: [
        {
          name: "退货退款",
          id: 0,
        },
        {
          name: "退货无需退款",
          id: 1,
        },
        {
          name: "仅退款",
          id: 2,
        },
        //   {
        //   name: '未退款',
        //   id: '0'
        // }, {
        //   name: '已退款',
        //   id: '1'
        // }
      ],
      goodsIds: [],
      selectGoods: [],
      time: [],
      loading: true,
      //搜索
      searchForm: {
        startTime: "",
        endTime: "",
        status: "",//单据状态：0.未退款；1.已退款
        driverId: "",//销售人员id（司机id）
      },
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 100,
        rows: [100, 200, 300, 400],
      },
      multipleTable: [],
      ids: [],
      searchButton: '',
      updateButton: '',
      addButton: '',
      delButton: '',
      infoButton: '',
      adcode: sessionStorage.getItem('adcode'),
      driverList: [],
      printData: {},
      printTableShow: false,
      printLoading: true,
      printObj: {
        id: "print",
        popTitle: "销售退货单列表",
        preview: false,
        previewTitle: '打印预览', // The title of the preview window. The default is 打印预览
        previewBeforeOpenCallback(vue) {
          console.log('正在加载预览窗口')
        },
        previewOpenCallback(vue) {
          console.log('已经加载完预览窗口')
        },
        beforeOpenCallback(vue) {
          console.log('打开之前')
        },
        openCallback(vue) {
          console.log('执行了打印')
        },
        closeCallback(vue) {
          console.log('关闭了打印工具')
        },
      }
    }
  },
  created() {

    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'tWxbzMrjwJcszJx_update') {
        this.updateButton = '1'
      } else if (item.authority == 'print_records_query') {
        this.searchButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_add') {
        this.addButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_delete') {
        this.delButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_info') {
        this.infoButton = '1'
      }
    });
  },
  mounted() {
    this.getSelectGoods()
    this.getDriverList()
    this.getTime()
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
    });

  },
  methods: {
    // 请求导出/打印表格Blob数据
    async getBlobTable(ids) {
      let _this = this;
      return new Promise(function (resolve, reject) {
        _this.$axios
          .get(_this.$axios.defaults.basePath + "/refundOrder/salesReturnOrderListExport", {
            responseType: 'blob',
            params: {
              ids: ids.join(',')
            }
          })
          .then((res) => {
            console.log(res)
            resolve(res.data)
          });
      })

    },
    // 打印
    async printEvent() {
      let _this = this;
      _this.$axios
        .get(_this.$axios.defaults.basePath + "/refundOrder/salesReturnOrderListPrint", {
          params: {
            ids: _this.ids.join(',')
          }
        })
        .then((res) => {
          console.log(res)
          _this.printData = res.data.data
          _this.printTableShow = true
        });
    },
    // 导出
    async exportEvent() {
      let _this = this;
      let blobData = await this.getBlobTable(this.ids)
      var eleLink = document.createElement("a");
      eleLink.download = "销售退货单.csv";
      eleLink.style.display = "none";

      var blob = new Blob([blobData]);
      eleLink.href = URL.createObjectURL(blob);
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      _this.exportShow = false;
      _this.$message({
        message: "导出成功",
        type: "success",
      });
      _this.exportForm = {};
      _this.exportCategoryId = [];
      // 然后移除
      document.body.removeChild(eleLink);
    },
    // 表格复选框是否可选中
    selectStatus(row, index) {
      if (row.status == 1) {
        //已退款的不能选择
        return false;
      } else {
        return true;
      }
    },
    //修改订单状态
    updateOrderStatus() {
      let _this = this;
      this.$confirm("是否确认执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            ids: _this.ids.join(","),
            type: 1
          }
          this.$axios({
            url: _this.$axios.defaults.basePath + "/refundOrder/updateRefundStatus",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(obj),
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.listEvent();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 获取司机下拉列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.data.errcode == 0) {
            let obj = {
              username: "自提",
              id: "0"
            }
            res.data.data.unshift(obj)
            _this.driverList = res.data.data;
          }
        });
    },
    getTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1);
      let day = String(date.getDate());
      let hour = String(date.getHours());
      let minutes = String(date.getMinutes());
      let seconds = String(date.getSeconds())
      month = month.length === 2 ? month : 0 + month;
      day = day.length === 2 ? day : 0 + day;
      hour = hour.length === 2 ? hour : 0 + hour;
      minutes = minutes.length === 2 ? minutes : 0 + minutes;
      seconds = seconds.length === 2 ? seconds : 0 + seconds;
      let startTime = `${year}-${month}-01 00:00:00`;
      let endTime = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
      let time = [startTime, endTime]
      this.time = time
      this.searchForm.startTime = startTime
      this.searchForm.endTime = endTime
      this.listEvent();
    },
    // 搜索商品多选
    searchGoodsMore(e) {
      this.searchForm.goodsId = e.join(',')
    },
    goSalesDelivery(row) {
      this.$router.push({ name: "salesReturn", params: row })
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property == "num" || column.property == "totalPrice") {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            // sums[index] += ' 元';
          } else {
            //   sums[index] = 'N/A';
          }
        }

      });

      return sums;
    },
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
            });
            _this.exportGoods = list;
            _this.selectGoods = list;
          }
        });
    },
    changeTime(e) {
      console.log(e)
      if (e) {
        this.searchForm.startTime = e[0]
        this.searchForm.endTime = e[1]
      } else {
        this.searchForm.startTime = ""
        this.searchForm.endTime = ""
      }

    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage
      _this.searchForm.size = _this.pagesData.currentRows
      this.$axios.get(_this.$axios.defaults.basePath + '/refundOrder/salesReturnOrderList', {
        params: _this.searchForm
      }).then(function (res) {
        if (res.data.errcode == 0) {
          _this.loading = false;
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent()
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent()
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 100
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => { this.searchForm[key] = '' })
      this.goodsIds = [];
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 100
      this.time = []
      this.getTime()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },
  }
}
</script>

<style  scoped>
.orderId {
  color: #409eff;
  cursor: pointer;
}
.boxMain >>> .el-table .el-table__footer-wrapper tr td .cell {
  font-weight: 600;
}
@page {
  size: auto;
  margin: 0mm;
}
</style>